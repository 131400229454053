import book from 'assets/icons/checkout/book.svg';
import thumbWithStars from 'assets/icons/checkout/thumb-with-stars.svg';
import family from 'assets/icons/checkout/family.svg';
import track from 'assets/icons/checkout/track.svg';
import music from 'assets/icons/checkout/music.svg';
import hearts from 'assets/icons/checkout/hearts.svg';
import blueBubble from 'assets/icons/blue-bubble.svg';
import pinkBubble from 'assets/icons/pink-bubble.svg';
import greenBubble from 'assets/icons/green-bubble.svg';
import orangeBubble from 'assets/icons/orange-bubble.svg';

export default {
  book,
  thumbWithStars,
  family,
  track,
  music,
  hearts,
  blueBubble,
  pinkBubble,
  greenBubble,
  orangeBubble,
};
